<template>
	<div class="card card-custom card-stretch gutter-b" ref="idirectGraphs">
		<div id="traffic-header" class="card-header border-0 align-items-center pr-1 mb-2" v-if="network">
			<div>
				<h3 class="card-title font-weight-bolder text-dark">{{ network }} Traffic</h3>
				<div class="clickable" @click="downloadCsv">Download CSV</div>
			</div>
			<div class="date-range" v-if="showDatePicker">
				<div id="close-picker" class="p-3" @click="showDatePicker = false"><i class="flaticon-close" /></div>
				<DateRangeSelector v-if="!error" @update="dateRangeChanged" :loaded="loaded" />
			</div>
			<div v-else class="pr-6" @click="showDatePicker = true">
				<span class="btn btn-icon btn-light btn-sm mr-3" v-b-tooltip.hover="'Select Date Range'">
					<i class="flaticon-calendar-with-a-clock-time-tools" style="font-size: 2em; color: #54a5ff;" />
				</span>
			</div>
		</div>
		<div class="card-body pl-1 py-0">
			<ITCSpinner :loaded="loaded">
				<Dygraph :data="dyData" :options="dyOpts" />
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
import { KMG, downloadCSV } from '@/helpers.js';

export default {
	name: 'NetworkGraph',
	components: {
		Dygraph: () => import('@/view/content/lib/dygraphs.vue'),
		DateRangeSelector: () => import('@/view/content/widgets/components/DateRangeSelector.vue'),
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	props: ['serverId', 'networkId'],
	data() {
		return {
			loaded: false,
			error: false,
			network: null,
			showDatePicker: window.innerWidth > 1500,
			data: null,
			dyData: null,
			dyOpts: {
				labels: ['Timestamp', 'Upstream', 'Downstream'],
				labelsKMB: true,
				strokeWidth: 1.5,
				fillGraph: true,
				fillAlpha: 0.06,
				series: {
					Downstream: {
						color: '#54a5ff',
					},
				},
				axes: {
					y: {
						valueFormatter: function(i) {
							return KMG(i);
						},
					},
				},
			},
		};
	},
	methods: {
		dateRangeChanged() {
			this.load();
		},
		downloadCsv() {
			let csv = 'Timestamp,Upstream(avg bps),Downstream(avg bps)\n';
			this.data.forEach(row => {
				csv += `${row.timestamp},${row.rx},${row.tx}\n`;
			});
			console.log(csv);
			downloadCSV(csv, this.network + ' Traffic Stats.csv');
		},
		load() {
			this.loaded = false;
			this.$http.get(`idirect/traffic/network/${this.serverId}/${this.networkId}`).then(resp => {
				this.data = resp.data.data.network.data;
				this.dyData = resp.data.data.network.data.map(row => [new Date(row.timestamp.replaceAll('-', '/')), row.rx, row.tx]);
				this.network = resp.data.data.network.name;
				this.loaded = true;
			});
		},
	},
	created() {
		this.load();
	},
};
</script>

<style scoped lang="scss">
#close-picker {
	display: none;
}
.clickable {
	color: $dark-50;
}
.clickable:hover {
	color: $primary-hover;
	cursor: pointer;
}
@media screen and (min-width: 200px) and (max-width: 1500px) {
	#close-picker {
		display: block;
	}
	#close-picker:hover i {
		cursor: pointer;
		color: $danger;
	}
	#traffic-header {
		position: relative;
	}
	.date-range {
		position: absolute;
		top: 15px;
		right: 5px;
		border: 1px solid rgb(233, 231, 231);
		border-radius: 5px;
		padding-top: 5px;
		background: white;
		display: flex;
		align-items: center;
	}
}
</style>
